.image-area {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
}
.image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.image-r {
	background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.image-f {
	background-size: contain;
	background-repeat: no-repeat;
  background-position: center center;
}
.image-b { background-position: center bottom; }
.img20 { padding-top: 20%; }
.img30 { padding-top: 30%; }
.img35 { padding-top: 35%; }
.img40 { padding-top: 40%; }
.img45 { padding-top: 45%; }
.img50 { padding-top: 50%; }
.img55 { padding-top: 55%; }
.img56 { padding-top: 56.2%; }
.img60 { padding-top: 60%; }
.img66 { padding-top: 66%; }
.img70 { padding-top: 70%; }
.img80 { padding-top: 80%; }
.img100 { padding-top: 100%; }
.img140 { padding-top: 140%; }

.circle-wrap {
	border-radius: 1000px;
	overflow: hidden;
}
.circle-wrap-hover .circle-target1,
.circle-wrap-hover .circle-target2 {
	transition: .3s;
}
.circle-wrap-hover:hover .circle-target1 {
	transform: scale(0.905797101,0.905797101);
}
.circle-wrap-hover:hover .circle-target2 {
	transform: scale(1.104,1.104);
}
.bg-cover1::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(1, 15, 226, 0.5);
}

.bg-cover-area {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.bg-cover-area-r {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
}
.icon-img {
	width: 24px;
	height: 24px;
}
.icon-img-s {
	width: 18px;
	min-width: 18px;
	max-width: 18px;
	height: 18px;
	min-height: 18px;
	max-height: 18px;
}
.icon-img-l {
	width: 36px;
	min-width: 36px;
	max-width: 36px;
	height: 36px;
	min-height: 36px;
	max-height: 36px;
}

.ftxxxl { font-size: 3.8em; }
.ftxxl { font-size: 3.2em; }
.ftxl { font-size: 2.8em; }
.ftl { font-size: 2.4em; }
.ft0 { font-size: 2em; }
.ft1 { font-size: 1.8em; }
.ft2 { font-size: 1.6em; }
.ft25 { font-size: 1.45em; }
.ft3 { font-size: 1.3em; }
.ft4 { font-size: 1.12em; }
.ft45 { font-size: .9em; }
.ft5 { font-size: .8em; }
.ft6 { font-size: .6em; }
.ft-th { font-weight: 100; }
.ft-el { font-weight: 200; }
.ft-lt { font-weight: 300; }
.ft-rg { font-weight: 400; }
.ft-md { font-weight: 500; }
.ft-b { font-weight: 600; }
.ft-eb { font-weight: 700; }
.ft-hv { font-weight: 800; }
.ft-bi { font-weight: 900; }

.lh-09 { line-height: .9; }
.lh-10 { line-height: 1; }
.lh-12 { line-height: 1.2; }
.lh-14 { line-height: 1.4; }
.lh-16 { line-height: 1.6; }
.lh-18 { line-height: 1.8; }
.lh-19 { line-height: 1.9; }
.lh-20 { line-height: 2; }
.lh-22 { line-height: 2.2; }
.lh-24 { line-height: 2.4; }

::selection {
  background: #e4002b;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: #e4002b;
  color: #fff;
  text-shadow: none;
}
::-webkit-selection {
  background: #e4002b;
  color: #fff;
  text-shadow: none;
}
.ft-rd { color: #ff0000; }


.ft-lg { color: #c1c1c1; }
.ft-lg0 { color: #ddd; }
.ft-gr { color: #999; }
.ft-gr0 { color: #777; }
.ft-wh, .ft-wh-hover:hover, .hover-wrap:hover .ft-wh-hover, .selected .ft-wh-sel, .selected.ft-wh-sel { color: #fff; }
.ft-bk, .ft-bk-hover:hover, .hover-wrap:hover .ft-bk-hover, .selected .ft-bk-sel, .selected.ft-bk-sel { color: #000; }
.ft-c0, .ft-c0-hover:hover, .hover-wrap:hover .ft-c0-hover, .selected .ft-c0-sel, .selected.ft-c0-sel { color: #18015D; }
.ft-c1, .ft-c1-hover:hover, .hover-wrap:hover .ft-c1-hover, .selected .ft-c1-sel, .selected.ft-c1-sel { color: #130178; }
.ft-c2, .ft-c2-hover:hover, .hover-wrap:hover .ft-c2-hover, .selected .ft-c2-sel, .selected.ft-c2-sel { color: #003da5; }
.ft-c3, .ft-c3-hover:hover, .hover-wrap:hover .ft-c3-hover, .selected .ft-c3-sel, .selected.ft-c3-sel { color: #010FE2; }
.ft-c4, .ft-c4-hover:hover, .hover-wrap:hover .ft-c4-hover, .selected .ft-c4-sel, .selected.ft-c4-sel { color: #e4002b; }

.bg-clr-lg0 { background: #e5e7e8; }
.bg-clr-bk, .bg-clr-bk-hover:hover, .bg-hover-wrap:hover .bg-clr-bk-hover, .bg-clr-bk-bf::before, .bg-clr-bk-af::after, .bg-clr-bk-sel.selected, .bg-clr-wrap.selected .bg-clr-bk-sel { background-color: #000; }
.bg-clr-wh, .bg-clr-wh-hover:hover, .bg-hover-wrap:hover .bg-clr-wh-hover, .bg-clr-wh-bf::before, .bg-clr-wh-af::after, .bg-clr-wh-sel.selected, .bg-clr-wrap.selected .bg-clr-wh-sel { background-color: #fff; }
.bg-clr-lg, .bg-clr-lg-hover:hover, .bg-clr-lg-bf::before, .bg-clr-lg-af::after, .bg-clr-lg-sel.selected, .bg-clr-wrap.selected .bg-clr-lg-sel  { background-color: #f0f1f1; }
.bg-clr-lg0, .bg-clr-lg0-hover:hover, .bg-clr-lg0-bf::before, .bg-clr-lg0-af::after, .bg-clr-lg0-sel.selected, .bg-clr-wrap.selected .bg-clr-lg0-sel  { background-color: #e5e7e8; }
.bg-clr-gr, .bg-clr-gr-hover:hover, .bg-clr-gr-bf::before, .bg-clr-gr-af::after, .bg-clr-gr-sel.selected, .bg-clr-wrap.selected .bg-clr-gr-sel  { background-color: rgb(159, 164, 169); }
.bg-clr-gr0, .bg-clr-gr0-hover:hover, .bg-clr-gr0-bf::before, .bg-clr-gr0-af::after, .bg-clr-gr0-sel.selected, .bg-clr-wrap.selected .bg-clr-gr0-sel  { background-color: #bec4c5; }
.bg-clr-0, .bg-clr-0-hover:hover, .bg-clr-0-bf::before, .bg-clr-0-af::after  { background-color: #18015D; }
.bg-clr-1, .bg-clr-1-hover:hover, .bg-clr-1-bf::before, .bg-clr-1-af::after  { background-color: #130178; }
.bg-clr-2, .bg-clr-2-hover:hover, .bg-clr-2-bf::before, .bg-clr-2-af::after { background-color: #003da5; }
.bg-clr-3, .bg-clr-3-hover:hover, .bg-clr-3-bf::before, .bg-clr-3-af::after  { background-color: #010FE2; }
.bg-clr-4, .bg-clr-4-hover:hover, .bg-clr-4-bf::before, .bg-clr-4-af::after  { background-color: #e4002b; }

.svg-rd svg, .svg-rd { stroke: #ff0000; }
.svg-c0 svg, .svg-c0 { stroke: #18015D; }
.svg-c1 svg, .svg-c1 { stroke: #130178; }
.svg-c2 svg, .svg-c2 { stroke: #003da5; }
.svg-c3 svg, .svg-c3 { stroke: #010FE2; }
.svg-c4 svg, .svg-c4 { stroke: #e4002b; }
.svg-wh-f svg, .svg-wh-f, .hover-wrap:hover .svg-wh-fhover, .svg-wh-fhover:hover { fill: #fff; }
.svg-c0-f svg, .svg-c0-f, .hover-wrap:hover .svg-c0-fhover, .svg-c0-fhover:hover { fill: #18015D; }
.svg-c1-f svg, .svg-c1-f, .hover-wrap:hover .svg-c1-fhover, .svg-c1-fhover:hover { fill: #130178; }
.svg-c2-f svg, .svg-c2-f, .hover-wrap:hover .svg-c2-fhover, .svg-c2-fhover:hover { fill: #003da5; }
.svg-c3-f svg, .svg-c3-f, .hover-wrap:hover .svg-c3-fhover, .svg-c3-fhover:hover { fill: #010FE2; }
.svg-c4-f svg, .svg-c4-f, .hover-wrap:hover .svg-c4-fhover, .svg-c4-fhover:hover { fill: #e4002b; }

.hover-cover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, .2);
  pointer-events: none;
  transition: .6s;
}
.hover-cover.hc-wh::after { background-color: rgba(255, 255, 255, .2); }
.hover-cover:hover::after {
  opacity: 1;
}
/* .hover-type,
.hover-type svg,
.hover-type .hover-with,
.hover-self {
	transition: .3s;
}
.hover-type:hover svg {
	fill: #0DFF9E;
}
.hover-type:hover .hover-with,
.hover-self:hover {
	color: #0DFF9E;
} */

.bd-bk { border: 1px solid #000; }
.bd-bk2 { border: 2px solid #000; }
.bd-tp-bk2 { border-top: 1px solid #000; }
.bd-wh { border: 1px solid #fff; }
.bd-wh3 { border: 1px solid rgba(255,255,255,.3); }
.bd-dg { border: 1px solid #999; }
.bd-dg3 { border: 3px solid #999; }
.bd-gr { border: 1px solid #ddd; }
.bd-tp-gr { border-top: 1px solid #ddd; }
.bd-tp-gr2 { border-top: 2px solid #ddd; }
.bd-bt-gr { border-bottom: 1px solid #ddd; }
.bd-rd-5 { border-radius: 5px; }
.bd-rd-10 { border-radius: 10px; }
.bd-rd-100 { border-radius: 100px; }
.bd-rd-500 { border-radius: 500px; }

.txt-right { text-align: right; }
.txt-left { text-align: left; }
.txt-center { text-align: center; }
.txt-underline { text-decoration: underline; }
.txt-wrap { word-break: break-all; word-wrap: normal; }
.txt-keep { word-break: keep-all; }
.txt-justify { text-align: justify; text-justify: auto; }
.txt-nowrap { white-space: nowrap; }
.v-mid { vertical-align: middle }

.mx1400 { max-width: 1400px; }
.mx1200 { max-width: 1200px; }
.mx1280 { max-width: 1280px; }
.mx1000 { max-width: 1000px; }
.mx120 { max-width: 120px; }
.mx170 { max-width: 170px; }
.mx200 { max-width: 200px; }
.mx250 { max-width: 250px; }
.mx300 { max-width: 300px; }
.mx400 { max-width: 400px; }
.mx450 { max-width: 450px; }
.mx540 { max-width: 540px; }
.mx600 { max-width: 600px; }
.mx700 { max-width: 700px; }
.mx800 { max-width: 800px; }
.min120 { min-width: 120px; }
.min170 { min-width: 170px; }
.min200 { min-width: 200px; }
.min400 { min-width: 400px; }
.min600 { min-width: 600px; }
.minh400 { min-height: 400px; }
.minh500 { min-height: 500px; }
.minh600 { min-height: 600px; }

.w10 { width: 10%; }
.w15 { width: 15%; }
.w20 { width: 20%; }
.w25 { width: 25%; }
.w30 { width: 30%; }
.w33 { width: 33.333%; }
.w40 { width: 40%; }
.w45 { width: 45%; }
.w50 { width: 50%; }
.w55 { width: 55%; }
.w60 { width: 60%; }
.w70 { width: 70%; }
.w80 { width: 80%; }
.w100 { width: 100%; }
.w50p { width: 50px; min-width: 50px; }
.w55p { width: 55px; min-width: 55px; }
.w110p { width: 110px; min-width: 110px; }
.w110p { width: 110px; min-width: 110px; }
.w150p { width: 150px; min-width: 150px; }
.w200p { width: 200px; min-width: 200px; }
.h100 { height: 100%; }
.h200p { height: 200px; }
.h300p { height: 300px; }
.h400p { height: 400px; }
.h600p { height: 600px; }

.pd-tmenu { padding-top: 71px; }
.mg-tmenu { margin-top: 71px; }

.mg-at { margin: auto; }
.mg-ct { margin-left: auto; margin-right: auto; }
.mg-la { margin-left: auto; }
.mg-ra { margin-right: auto; }
.mg-s0 { margin-left: 0; margin-right: 0; }
.mg-5 { margin: 5px; }
.mg-h5 { margin-top: 5px; margin-bottom: 5px; }
.mg-t5 { margin-top: 5px; }
.mg-b5 { margin-bottom: 5px; }
.mg-s5 { margin-left: 5px; margin-right: 5px; }
.mg-l5 { margin-left: 5px; }
.mg-r5 { margin-right: 5px; }
.mg-15 { margin: 15px; }
.mg-h15 { margin-top: 15px; margin-bottom: 15px; }
.mg-t15 { margin-top: 15px; }
.mg-b15 { margin-bottom: 15px; }
.mg-s15 { margin-left: 15px; margin-right: 15px; }
.mg-l15 { margin-left: 15px; }
.mg-r15 { margin-right: 15px; }
.mg-10 { margin: 10px; }
.mg-h10 { margin-top: 10px; margin-bottom: 10px; }
.mg-t10 { margin-top: 10px; }
.mg-b10 { margin-bottom: 10px; }
.mg-s10 { margin-left: 10px; margin-right: 10px; }
.mg-l10 { margin-left: 10px; }
.mg-r10 { margin-right: 10px; }
.mg-20 { margin: 20px; }
.mg-h20 { margin-top: 20px; margin-bottom: 20px; }
.mg-t20 { margin-top: 20px; }
.mg-b20 { margin-bottom: 20px; }
.mg-s20 { margin-left: 20px; margin-right: 20px; }
.mg-l20 { margin-left: 20px; }
.mg-r20 { margin-right: 20px; }
.mg-30 { margin: 30px; }
.mg-h30 { margin-top: 30px; margin-bottom: 30px; }
.mg-t30 { margin-top: 30px; }
.mg-b30 { margin-bottom: 30px; }
.mg-s30 { margin-left: 30px; margin-right: 30px; }
.mg-l30 { margin-left: 30px; }
.mg-r30 { margin-right: 30px; }
.mg-40 { margin: 40px; }
.mg-h40 { margin-top: 40px; margin-bottom: 40px; }
.mg-t40 { margin-top: 40px; }
.mg-b40 { margin-bottom: 40px; }
.mg-s40 { margin-left: 40px; margin-right: 40px; }
.mg-l40 { margin-left: 40px; }
.mg-r40 { margin-right: 40px; }
.mg-60 { margin: 60px; }
.mg-h60 { margin-top: 60px; margin-bottom: 60px; }
.mg-t60 { margin-top: 60px; }
.mg-b60 { margin-bottom: 60px; }
.mg-s60 { margin-left: 60px; margin-right: 60px; }
.mg-l60 { margin-left: 60px; }
.mg-r60 { margin-right: 60px; }
.mg-80 { margin: 80px; }
.mg-h80 { margin-top: 80px; margin-bottom: 80px; }
.mg-t80 { margin-top: 80px; }
.mg-b80 { margin-bottom: 80px; }
.mg-s80 { margin-left: 80px; margin-right: 80px; }
.mg-l80 { margin-left: 80px; }
.mg-r80 { margin-right: 80px; }
.mg-100 { margin: 100px; }
.mg-h100 { margin-top: 100px; margin-bottom: 100px; }
.mg-t100 { margin-top: 100px; }
.mg-b100 { margin-bottom: 100px; }
.mg-s100 { margin-left: 100px; margin-right: 100px; }
.mg-l100 { margin-left: 100px; }
.mg-r100 { margin-right: 100px; }

.pd-2 { padding: 2px; }
.pd-s2 { padding-left: 2px; padding-right: 2px; }
.pd-l2 { padding-left: 2px; }
.pd-r2 { padding-right: 2px; }
.pd-h2 { padding-top: 2px; padding-bottom: 2px; }
.pd-t2 { padding-top: 2px; }
.pd-b2 { padding-bottom: 2px; }
.pd-5 { padding: 5px; }
.pd-s5 { padding-left: 5px; padding-right: 5px; }
.pd-l5 { padding-left: 5px; }
.pd-r5 { padding-right: 5px; }
.pd-h5 { padding-top: 5px; padding-bottom: 5px; }
.pd-t5 { padding-top: 5px; }
.pd-b5 { padding-bottom: 5px; }
.pd-10 { padding: 10px; }
.pd-s10 { padding-left: 10px; padding-right: 10px; }
.pd-l10 { padding-left: 10px; }
.pd-r10 { padding-right: 10px; }
.pd-h10 { padding-top: 10px; padding-bottom: 10px; }
.pd-t10 { padding-top: 10px; }
.pd-b10 { padding-bottom: 10px; }
.pd-15 { padding: 15px; }
.pd-s15 { padding-left: 15px; padding-right: 15px; }
.pd-l15 { padding-left: 15px; }
.pd-r15 { padding-right: 15px; }
.pd-h15 { padding-top: 15px; padding-bottom: 15px; }
.pd-t15 { padding-top: 15px; }
.pd-b15 { padding-bottom: 15px; }
.pd-20 { padding: 20px; }
.pd-s20 { padding-left: 20px; padding-right: 20px; }
.pd-l20 { padding-left: 20px; }
.pd-r20 { padding-right: 20px; }
.pd-h20 { padding-top: 20px; padding-bottom: 20px; }
.pd-t20 { padding-top: 20px; }
.pd-b20 { padding-bottom: 20px; }
.pd-25 { padding: 25px; }
.pd-s25 { padding-left: 25px; padding-right: 25px; }
.pd-l25 { padding-left: 25px; }
.pd-r25 { padding-right: 25px; }
.pd-h25 { padding-top: 25px; padding-bottom: 25px; }
.pd-t25 { padding-top: 25px; }
.pd-b25 { padding-bottom: 25px; }
.pd-30 { padding: 30px; }
.pd-s30 { padding-left: 30px; padding-right: 30px; }
.pd-l30 { padding-left: 30px; }
.pd-r30 { padding-right: 30px; }
.pd-h30 { padding-top: 30px; padding-bottom: 30px; }
.pd-t30 { padding-top: 30px; }
.pd-b30 { padding-bottom: 30px; }
.pd-40 { padding: 40px; }
.pd-s40 { padding-left: 40px; padding-right: 40px; }
.pd-l40 { padding-left: 40px; }
.pd-r40 { padding-right: 40px; }
.pd-h40 { padding-top: 40px; padding-bottom: 40px; }
.pd-t40 { padding-top: 40px; }
.pd-b40 { padding-bottom: 40px; }
.pd-60 { padding: 60px; }
.pd-s60 { padding-left: 60px; padding-right: 60px; }
.pd-l60 { padding-left: 60px; }
.pd-r60 { padding-right: 60px; }
.pd-h60 { padding-top: 60px; padding-bottom: 60px; }
.pd-t60 { padding-top: 60px; }
.pd-b60 { padding-bottom: 60px; }
.pd-80 { padding: 80px; }
.pd-s80 { padding-left: 80px; padding-right: 80px; }
.pd-l80 { padding-left: 80px; }
.pd-r80 { padding-right: 80px; }
.pd-h80 { padding-top: 80px; padding-bottom: 80px; }
.pd-t80 { padding-top: 80px; }
.pd-b80 { padding-bottom: 80px; }
.pd-100 { padding: 100px; }
.pd-s100 { padding-left: 100px; padding-right: 100px; }
.pd-l100 { padding-left: 100px; }
.pd-r100 { padding-right: 100px; }
.pd-h100 { padding-top: 100px; padding-bottom: 100px; }
.pd-t100 { padding-top: 100px; }
.pd-b100 { padding-bottom: 100px; }



.inst-box {
	z-index: 1;
	position: relative;
}
.block-box {
	display: block;
}
.iblock-box {
	display: inline-block;
	position: relative;
}
.zIdx1 { z-index: 1; }
.zIdx2 { z-index: 2; }
.oflow-h { overflow: hidden; }

.box-shadow-type0 { box-shadow: 4px 4px 0px 0px rgba(0,0,0,.1); }
.box-shadow-type1 { box-shadow: 0 0 40px 10px rgba(0,0,0,.1); }
.underline-title { position: relative; }
.underline-title::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 40px;
	height: 4px;
	background-color: #18015D;
}
.opacity-2 { opacity: .2; }

.cross-btn {
	display: inline-block;
	position: relative;
	width: 24px;
	height: 24px;
	border: 1px solid #000;
	border-radius: 100px;
	background-image: url('/dist/img/icon/plus.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}
.cross-btn.clr-dg {
	background-image: url('/dist/img/icon/plus_dg.svg');
}
.cross-btn.type2 {
	border: none;
	border-radius: 0;
}
.cross-btn.type3 {
	border: none;
	border-radius: 0;
	transform: rotate(45deg);
}

.cross-btn-hover .cross-btn {
	transition: transform .3s;
}
.cross-btn-hover:hover .cross-btn {
	transform: rotate(180deg);
}

.cursor-point { cursor: pointer }
.w12-g5 { width: calc( 12.5% - 10px ); }
.w12-g10 { width: calc( 12.5% - 20px ); }
.w12-g20 { width: calc( 12.5% - 40px ); }
.w16-g5 { width: calc( 16.66667% - 10px ); }
.w16-g10 { width: calc( 16.66667% - 20px ); }
.w16-g20 { width: calc( 16.66667% - 40px ); }
.w20-g5 { width: calc( 20% - 10px ); }
.w20-g10 { width: calc( 20% - 20px ); }
.w20-g20 { width: calc( 20% - 40px ); }
.w33-g5 { width: calc( 33.3333% - 10px ); }
.w33-g10 { width: calc( 33.3333% - 20px ); }
.w33-g20 { width: calc( 33.3333% - 40px ); }
.w50-g5 { width: calc( 50% - 10px ); }
.w50-g10 { width: calc( 50% - 20px ); }
.w50-g30 { width: calc( 50% - 60px ); }
.w50-g40 { width: calc( 50% - 80px ); }
.w25-g5 { width: calc( 25% - 10px ); }
.w25-g10 { width: calc( 25% - 20px ); }
.w25-g20 { width: calc( 25% - 40px ); }
.w25-g30 { width: calc( 25% - 60px ); }
.w25-g40 { width: calc( 25% - 80px ); }
.w33-g10 { width: calc( 33.3333% - 20px ); }
.w33-g20 { width: calc( 33.3333% - 40px ); }
.w33-g30 { width: calc( 33.3333% - 60px ); }
.w33-g40 { width: calc( 33.3333% - 80px ); }
.w50-g10 { width: calc( 50% - 20px ); }
.w50-g20 { width: calc( 50% - 40px ); }
.grid-5 { width: calc(100% + 10px); margin-left: -5px; }
.grid-10 { width: calc(100% + 20px); margin-left: -10px; }
.grid-20 { width: calc(100% + 40px); margin-left: -20px; }
.grid-30 { width: calc(100% + 60px); margin-left: -30px; }
.grid-40 { width: calc(100% + 80px); margin-left: -40px; }

.col-2 { column-count: 2; }
.col-g2 { column-gap: 2em; }
.col-g4 { column-gap: 4em; }

.float-l { float: left; }
.flx-odr-0 { order: 0; }
.flx-odr-1 { order: 1; }
.flx-odr-2 { order: 2; }
.flx-0 {
	display: flex;
}
.flx-0c {
	display: flex;
	justify-content: center;
}
.flx-col {
	display: flex;
	flex-direction: column;
}
.flx-col-w {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
.flx-col-cc {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.flx-col-cl {
	display: flex;
	flex-direction: column;
  align-items: flex-start;
	justify-content: center;
}
.flx-col-btw {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.flx-cl {
	display: flex;
	align-items: center;
}
.flx-cc {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flx-icc {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
.flx-cr {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.flx-sr {
	display: flex;
	justify-content: flex-end;
}
.flx-end {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}
.flx-el {
	display: flex;
	align-items: flex-end;
}
.flx-sb {
	display: flex;
	justify-content: space-between;
}
.flx-sbc {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.flx-cs {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.flx-w {
	display: flex;
	flex-wrap: wrap;
}
.flx-itm-r { flex: 1; flex-basis: auto; }
.flx-itm-r10 { flex: 1 0 auto; }
.flx-itm-r0 { flex: 1; flex-basis: 0; }
.flx-itm-r1 { flex: 1; flex-basis: 1; }
.flx-itm-end {
	justify-content: flex-end;
}
.display-hidden { display: none; }

.quote-style::before,
.quote-style::after {
	content: '';
	display: inline-block;
	align-self: flex-start;
	position: relative;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}
.quote-style::before { background-image: url('/dist/img/icon/quote-top1.svg'); }
.quote-style::after { background-image: url('/dist/img/icon/quote-bottom1.svg'); }
.quote-style.quote-s::before, .quote-style.quote-s::after { min-width: 24px; min-height: 24px; }
.quote-style.quote-m::before, .quote-style.quote-m::after { min-width: 36px; min-height: 36px; }
.quote-style.quote-l::before, .quote-style.quote-l::after { min-width: 48px; min-height: 48px; }

.centerline-after { display: flex; align-items: center; }
.centerline-after::after {
	content: '';
	position: relative;
	flex: 1 0 auto;
	height: 1px;
	background-color: #ccc;
}
.leftline-h { display: flex; }
.leftline-h::before {
	content: '';
	position: relative;
	width: 5px;
	margin-top: .15em;
	margin-bottom: .15em;
}
.leftline-v { display: flex; align-items: center; }
.leftline-v::before {
	content: '';
	position: relative;
	width: 20px;
	height: 2px;
}
.leftline-vt { display: flex; }
.leftline-vt::before {
	content: '';
	position: relative;
	width: 20px;
	height: 2px;
	margin-top: .15em;
	margin-bottom: .15em;
}
.leftline-vb { display: flex; align-items: flex-end;}
.leftline-vb::before {
	content: '';
	position: relative;
	width: 20px;
	height: 2px;
	margin-top: .15em;
	margin-bottom: .15em;
}

.mark-img {
	background-size: auto 1em;
	background-position: left .25em;
	background-repeat: no-repeat;
}
.mark-img-s {
	background-size: 16px 16px;
	background-position: left .25em;
	background-repeat: no-repeat;
}
.mark-img-m {
	background-size: 24px 24px;
	background-position: left .25em;
	background-repeat: no-repeat;
}
.mark-img-l {
	background-size: 32px 32px;
	background-position: left .25em;
	background-repeat: no-repeat;
}
.mark-img-f {
	background-size: contain;
	background-position: left center;
	background-repeat: no-repeat;
}
.mark-each {
	position: relative;
	padding-left: 20px;
}
.mark-each::before {
	content: '';
	position: absolute;
	top: .44em;
	left: .4em;
	width: 6px;
	height: 6px;
	/*border-radius: 10px;*/
}
.mark-each.me-lh16::before { top: .58em; }
.mark-each.me-lh18::before { top: .7em; }

.image-slide {
	opacity: 0;
	visibility: hidden;
}
.image-slide.type1 {
	position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.image-slide.type2 {
	display: none;
	position: relative;
}
.image-slide.selected {
	/* animation-name: imgSlideFadeIn;
	animation-duration: 7s;
	animation-delay: .1s;
	animation-fill-mode: forwards; */

	animation: imgSlideFadeIn 5s .1s forwards;
}
.image-slide.off {
	/* animation-name: imgSlideFadeOut;
	animation-duration: 7s;
	animation-delay: 0s;
	animation-fill-mode: forwards; */

	animation: imgSlideFadeOut 5s forwards;
}
.image-slide.type1.selected {
	display: flex;
}
.image-slide.type2.selected {
	display: block;
}

@keyframes imgSlideFadeIn {
	0% {
		visibility: hidden;
		opacity: 0;
	}	
	20% {
		visibility: visible;
		opacity: 1;
	}
	100% {
		visibility: visible;
		opacity: 1;
	}
}
@keyframes imgSlideFadeOut {
	0% {
		visibility: visible;
		opacity: 1;
	}
	20% {
		visibility: hidden;
		opacity: 0;
	}
	100% {
		visibility: hidden;
		opacity: 0;
	}
}
.delay1 {
	animation-delay: .6s;
}
.delay2 {
	animation-delay: .9s;
}
.delay3 {
	animation-delay: 1.2s;
}
.slide-arrow-area {
	z-index: 3;
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 0;
	padding: 0px 20px 0;
	transform: translateX(-50%);
}
.slide-arrow {
	width: 54px;
	height: 54px;
	cursor: pointer;
	background-size: 80% 80%;
	background-position: center;
	background-repeat: no-repeat;
	background-color: rgba(255,255,255,.4);
	border-radius: 100px;
	transform: translateY(-50%);
}
.slide-arrow.arrow-small {
	width: 34px;
	height: 34px;
}
.slide-arrow.prev {
	background-image: url('/dist/img/icon/left-arrow.svg');
}
.slide-arrow.next {
	background-image: url('/dist/img/icon/right-arrow.svg');
}
.bullets {
	z-index: 1;
	position: relative;
	text-align: center;
}
.bullets li {
	display: inline-block;
	cursor: pointer;
}

.bullets.type1 {
	padding: 20px 0;
}
.bullets.type1 li {
	width: 40px;
	height: 4px;
	margin: 4px;
	background-color: #eee;
}
.bullets.type2 {
	padding: 10px 40px;
}
.bullets.type2.pos-bt {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
}
.bullets.type2 li {
	width: 6px;
	height: 6px;
	margin: 4px;
	border-radius: 10px;
	background-color: #ddd;
}
.bullets.type1 li.selected,
.bullets.type2 li.selected {
	background-color: #000;
}

.button {
	position: relative;
	overflow: hidden;
}
.button::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
/*	background-color: #18015D;*/
	transform-origin: 0 50%;
	transform: scale(0, 1);
	transition: transform .3s;
}
.button:hover::before {
	transform: scale(1, 1);
}
.action-btn {
	z-index: 1;
	display: inline-block;
	position: relative;
	width: 48px;
	height: 48px;
	padding: 5px;
	cursor: pointer;
	border-radius: 30px;
	background-color: rgba(255,255,255,.4);
}
.action-btn.act-btn-lg { background-color: rgba(221,221,221,.4); }
.action-btn.nobg {
	background: none;
}
.action-btn.act-btn-right {
	/*width: 34px;
	height: 34px;*/
	padding: 2px;
	transform: scale(.7,.7) rotate(-90deg);
}
.action-btn .line {
	position: relative;
	width: 100%;
	height: 100%;
}
.action-btn .line svg {
	fill: none;
	stroke: #F2845C;
	stroke-linecap: round;
	stroke-dasharray: 189% 40%;
	transition-property: stroke-dasharray;
	transition-duration: .6s;
	transition-delay: 0s;
}
.action-btn-hover {
	cursor: pointer;
}
.action-btn-hover:hover .action-btn .line svg,
.action-btn:hover .line svg {
	stroke-dasharray: 209% 0%;
	transition-delay: .2s;
}
.action-btn .stick {
	position: absolute;
	bottom: 31%;
	left: 50%;
	width: 2px;
	height: 44%;
	margin-left: -1px;
	border-radius: 100px;
	transform-origin: 50% 100%;
	transition-property: transform;
	transition-duration: .4s;
	transition-delay: .3s;
}
.action-btn-hover:hover .action-btn .stick,
.action-btn:hover .stick {
	transform: scale3d(1,0,0);
	transition-delay: 0s;
}
.action-btn .fig {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -12px;
	margin-top: -8px;
	width: 24px;
	height: 24px;
	/*border: 4px solid transparent;
	border-top: 4px solid #F2845C;*/
	/*background-image: url('/dist/img/icon/fig.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;*/
}
.action-btn .fig svg {
	position: relative;
	width: 100%;
	stroke-width: 2;
	fill: none;
}


#top-scroll-btn {
	position: fixed;
	bottom: 10%;
	right: 10%;
	opacity: 0;
	visibility: hidden;
	transform: rotate(-180deg) scale3d(0,0,1);
	transition: .6s;
}
#top-scroll-btn.show {
	opacity: 1;
	visibility: visible;
	transform: rotate(180deg) scale3d(1,1,1);
}


.tr-3 { transition: .3s; }
.tr-trigger {}
.tr-rise-target {
	opacity: 0;
	transform: translateY(10px);
	transition-property: opacity, transform;
	transition-duration: .3s;
}
.tr-trigger.on .tr-rise-target {
	opacity: 1;
	transform: translateY(0);
}

.tr-zoom-target {
	opacity: 0;
	transform: scale3d(.3, .3, 1);
	transition-property: opacity, transform;
	transition-duration: .3s;
}
.tr-trigger.on .tr-zoom-target {
	opacity: 1;
	transform: scale3d(1, 1, 1);
}

.tr-delay1 { transition-delay: .1s; }
.tr-delay2 { transition-delay: .2s; }
.tr-delay3 { transition-delay: .3s; }
.tr-delay4 { transition-delay: .4s; }
.tr-delay5 { transition-delay: .5s; }
.tr-delay6 { transition-delay: .6s; }
.tr-delay7 { transition-delay: .7s; }
.tr-delay8 { transition-delay: .8s; }
.tr-delay9 { transition-delay: .9s; }
.tr-delay10 { transition-delay: 1s; }
.tr-delay11 { transition-delay: 1.1s; }
.tr-delay12 { transition-delay: 1.2s; }
.tr-delay13 { transition-delay: 1.3s; }
.tr-delay14 { transition-delay: 1.4s; }
.tr-delay15 { transition-delay: 1.5s; }
.tr-delay16 { transition-delay: 1.6s; }
.tr-delay17 { transition-delay: 1.7s; }
.tr-delay18 { transition-delay: 1.8s; }
.tr-delay19 { transition-delay: 1.9s; }

.tr-trigger.off .tr-delay1,
.tr-trigger.off .tr-delay2,
.tr-trigger.off .tr-delay3,
.tr-trigger.off .tr-delay4,
.tr-trigger.off .tr-delay5,
.tr-trigger.off .tr-delay6,
.tr-trigger.off .tr-delay7,
.tr-trigger.off .tr-delay8,
.tr-trigger.off .tr-delay9,
.tr-trigger.off .tr-delay10,
.tr-trigger.off .tr-delay11,
.tr-trigger.off .tr-delay12,
.tr-trigger.off .tr-delay13,
.tr-trigger.off .tr-delay14,
.tr-trigger.off .tr-delay15,
.tr-trigger.off .tr-delay16,
.tr-trigger.off .tr-delay17,
.tr-trigger.off .tr-delay18,
.tr-trigger.off .tr-delay19 { transition-delay: 0s; }