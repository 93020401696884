@media screen and ( max-width: 1000px ) {
	.m100-vflex { display: flex; }
	.m100-flx-w { display: flex; flex-wrap: wrap; }
	.m100-hidden { display: none; }
	.m100-mxw-0.in-width { max-width: none; }
	.m100-w100 { width: 100%; }
	.m100-w50 { width: 50%; }
	.m100-mg-20 { margin: 20px; }
	.m100-mg-t20 { margin-top: 20px; }
}
@media screen and ( max-width: 800px ) {
	.footer-center-line::after { content: none; }
	.pd-tmenu { padding-top: 62px; }
	.mg-tmenu { margin-top: 62px; }
	.m-ftl { font-size: 1.8em; }
	.m-ft0 { font-size: 1.6em; }
	.m-ft1 { font-size: 1.4em; }
	.m-ft2 { font-size: 1.2em; }
	.m-ft3 { font-size: 1em; }
	.m-ft4 { font-size: .8em; }
	.m-ft-wh { color: #fff; }
	.m-ft-bk { color: #000; }
	.m-ft-lg { color: #eee; }
	.m-hidden { display: none; }
	.m-inst-box { position: relative; }
	.m-img40 { padding-top: 40%; }
	.m-img50 { padding-top: 50%; }
	.m-img60 { padding-top: 60%; }
	.m-img100 { padding-top: 100%; }
	.m-img130 { padding-top: 130%; }
	.m-flx-bsauto { flex-basis: auto; }
	.m-flx-col { flex-direction: column; align-items: flex-start; }
	.m-flx-col-cc { flex-direction: column; align-items: center; justify-content: center; }
	.m-flx-w { flex-wrap: wrap; }
	.m-flx-wc { display: flex; flex-wrap: wrap; justify-content: center; }
	.m-flx-0c {
		display: flex;
		align-items: center;
		flex-direction: row;
	}
	.m-order-0 { order: 0; }
	.m-order-1 { order: 1; }
	.m-col-1 { column-count: 1; }
	.m-grid-10 { width: calc(100% + 20px); margin-left: -10px; }
	.m-grid-20 { width: calc(100% + 40px); margin-left: -20px; }
	.m-w50-g5 { width: calc(50% - 10px); }
	.m-w50-g10 { width: calc(50% - 20px); }
	.m-w50-g20 { width: calc(50% - 40px); }
	.m-w100 { width: 100%; }
	.m-w50 { width: 50%; }
	.m-w60 { width: 60%; }
	.m-w40 { width: 40%; }
	.m-w33 { width: 33.3333%; }
	.m-mxw-0 { max-width: none; }
	.m-minw-0 { min-width: auto; }
	.m-minh-0 { min-height: auto; }
	.m-mg-ct { margin-left: auto; margin-right: auto; }
	.m-mg-0 { margin: 0; }
	.m-mg-h0 { margin-top: 0px; margin-bottom: 0px; }
	.m-mg-s0 { margin-left: 0; margin-right: 0; }
	.m-mg-10 { margin: 10px; }
	.m-mg-20 { margin: 20px; }
	.m-mg-t20 { margin-top: 20px; }
	.m-mg-b20 { margin-bottom: 20px; }
	.m-mg-l20 { margin-left: 20px; }
	.m-pd-0 { padding: 0; }
	.m-pd-b0 { padding-bottom: 0; }
	.m-pd-s0 { padding-left: 0; padding-right: 0; }
	.m-pd-10 { padding: 10px; }
	.m-pd-h10 { padding-top: 10px; padding-bottom: 10px; }
	.m-pd-s10 { padding-left: 10px; padding-right: 10px; }
	.m-pd-s20 { padding-left: 20px; padding-right: 20px;}
	.m-pd-t20 { padding-top: 20px; }
	.m-pd-20 { padding: 20px; }
	.m-pd-l20 { padding-left: 20px; }
	.m-pd-40 { padding: 40px; }
	.m-pd-s40 { padding-left: 40px; padding-right: 40px;}
	.m-pd-t40 { padding-top: 40px; }
	.m-pd-b40 { padding-bottom: 40px; }
	.m-pd-h40 { padding-top: 40px; padding-bottom: 40px; }
	.m-txt-center { text-align: center; }
	.m-txt-left { text-align: left; }
	.m-bd-bt-gr { border-bottom: 1px solid #ddd; }
}
@media screen and ( max-width: 600px ) {
	.m60-flx-col { flex-direction: column; align-items: flex-start; }
	.m60-w100 { width: 100%; }
	.m60-w50 { width: 50%; }
	.m60-block { display: block; }
	.m60-grid-5 { width: calc(100% + 10px); margin-left: -5px; }
	.m60-w50-g5 { width: calc(50% - 10px); }
	.m60-w16-g5 { width: calc(16.6667% - 10px); }
	.m60-pd-s0 { padding-left: 0; padding-right: 0; }
}
@media screen and ( max-width: 480px ) {
	.m48-hidden { display: none; }
	.m48-show { display: block; }
	.m48-flx-col { flex-direction: column; align-items: stretch;}
	.m48-w100 { width: 100%; }
	.m48-w36p { width: 36px; flex-basis: 36px }
	.m48-pdt0 { padding-top: 0; }
	.m48-pd-s0 { padding-left: 0; padding-right: 0; }
	.m48-pd-s10 { padding-left: 10px; padding-right: 10px; }
	.m48-pd20 { padding: 20px; }
	.m48-flx-0c {
		display: flex;
		align-items: center;
		flex-direction: row;
	}
	.m48-img30 { padding-top: 30%; }
}