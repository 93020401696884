.tab-content.hide {
	transition-property: visibility;
	transition-duration: 0s;
}
.tab-content {
	visibility: hidden;
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	transition-duration: .3s, .3s, .3s, 0s;
	transition-property: visibility, opacity, max-height, max-width;
	transition-delay: 0s, 0s, .2s, .2s;
}
.tab-content.selected {
	/*max-height: 8300px;*/
	visibility: visible;
	opacity: 1;
	transition-delay: .2s;
}

.tab-content.tab-width {
	max-width: 0;
}
.tab-content.tab-width.selected {
	max-width: 1000px;
}

.tab-content.tab-accd {
	visibility: visible;
	opacity: 1;
	transition-duration: .3s;
	transition-property: visibility, opacity, max-height, max-width;
	transition-delay: 0.05s;
	transition-timing-function: linear;
}
.tab-content.tab-accd.selected {
	/*max-height: 600px;*/
	transition-duration: .3s;
	transition-delay: 0.05s;
	transition-timing-function: linear;
}