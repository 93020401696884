@media screen and (max-width: 1200px) {
	.item-box-area .item-wrap.w25 {
		width: 50%;
		margin-left: auto;
		margin-right: auto;
	}
	#section01-wrap .cont-area .subject {
		font-size: 2.8em;
	}
}
@media screen and (max-width: 1000px) {
	.sect-title-area.sect-title-m1000 {
		flex-direction: row;
		align-items: center;
		position: relative;
		left: 20px;
	}
	.sect-title-area.sect-title-m1000 .num::after {
		content: none;
	}
	.sect-title-area.sect-title-m1000 .title {
		padding-left: 20px;
		bottom: auto;
		transform: none;
	}

	#section06-wrap .contact-area {
		padding-top: 0;
	}
	#section06-wrap .contact-cont {
		padding: 20px;
	}
	#section06-wrap .contatact-wrap {
		position: relative;
	}
}
@media screen and (max-width: 900px) {}
@media screen and (max-width: 800px) {
	#menu-area {
		display: block;
	}
	#menu-cont-area.head-part {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin-top: 62px;
		padding: 0;
		text-align: center;
		background: none;
		transform-origin: 50% 0;
		transform: scale(1,0);
		transition: .6s .3s;
	}
	#menu-cont-area.on {
		transform: scale(1,1);
		transition: .6s;
	}
	#menu-cont {
		width: 100%;
		padding: 20px;
		background-color: #fff;
		overflow-y: auto;
    min-height: calc(100vh - 62px);
    height: 0;
	}
	#menu-cont .menu-item {
    display: flex;
    flex-direction: column;
		/*color: #FFF;*/
		z-index: 1;
		position: relative;
		margin: 0vh 0;
		padding: .8em;
		overflow: hidden;
		background: none;
		opacity: 0;
		transform: translate3d(0, 40%, 0);
		transition-property: transform, opacity;
		transition-duration: .3s;
	}
	#menu-cont .menu-item .item {
		border-bottom: 1px solid #999;
	}
	#menu-cont .menu-item .item::after {
		content: none;
	}
	#menu-cont-area.on .menu-item {
		opacity: 1;
		transform: translate3d(0, 0%, 0);
		transition-duration: .3s;
	}
	#menu-cont-area.on .list0 {
		transition-delay: .6s;
	}
	#menu-cont-area.on .list1 {
		transition-delay: .7s;
	}
	#menu-cont-area.on .list2 {
		transition-delay: .8s;
	}
	#menu-cont-area.on .list3 {
		transition-delay: .9s;
	}
	#menu-cont-area.on .list4 {
		transition-delay: 1s;
	}
	#menu-cont-area.on .list5 {
		transition-delay: 1.1s;
	}
	#menu-cont-area.on .list6 {
		transition-delay: 1.2s;
	}
	#menu-cont-area.on .list7 {
		transition-delay: 1.3s;
	}
	#menu-cont .menu-item .item {
		padding: 5px;
		font-weight: 600;
	}
	#menu-cont .sub-menu-list {
		position: relative;
		padding: 6px;
		opacity: 1;
		visibility: visible;
		border: none;
		transform: translateY(0%) scale3d(1,1,1);
	}
	#menu-cont .sub-menu-list .sub-menu-item {
		padding: 2px 10px;
		opacity: 1;
		border: none;
	}
	#menu-cont .menu-item:hover .sub-menu-list,
	#menu-cont .menu-item.selected .sub-menu-list {
		transform: translateY(0%);
	}

	.sect-title-area {
		position: relative;
		left: 0;
	}
	.sect-title-area .num::after {
		content: none;
	}
	.sect-title-area .title {
		bottom: auto;
		transform: none;
	}
	#section00-wrap .flex {
		padding-bottom: 40px;
	}
	#section00-wrap .btn-area {
		padding-bottom: 0;
	}
	#section00-wrap .bottom {
		flex-direction: column;
	}
	#section00-wrap .bottom .text-wrap {
		display: none;
	}
	#scroll-area {
		display: none;
	}
	
	.item-box-area .item-wrap,
	.item-box-area .item-wrap.w25,
	.item-box-area .item-wrap.w50 {
		width: 100%;
		margin-left: auto;
    	margin-right: auto;
		padding-left: 0;
		padding-right: 0;
	}
	.section .subject-area {
		font-size: 2em;
	}
	.w33m {
		width: 100%;
	}
	.bd-sd-m {
		border: none;
	}
	#form-mail-area {
		width: 100%;
		border: none;
	}
	#section04-wrap .address,
	#section04-wrap .info-list {
		margin: 0 auto;
		padding-bottom: 20px;
	}
	
	#section05-wrap .board_list {
	}
	#section05-wrap .board_list.selected,
	#section00-wrap .board_list.off {
		flex-direction: column;
		-webkit-flex-direction: column;
    	-ms-flex-direction: column;
	}
	#section05-wrap .board_list .frame {
		width: 80%;
	}
	#section05-wrap .board_list .cont-area {
		width: 100%;
		padding-top: 20px;
	}
	
	#section04 .cont-area .cont-wrap {
		font-size: .7em;
		column-count: 2;
	}
	#temp02-wrap {
		padding: 20px;
	}
	#temp02-wrap .temp-flex {
		margin: 50px 0;
	}
	#footer-area {
		padding: 0;
	}
	#pop-notice .notice-wrap {
		flex-direction: column;
	}
}
@media screen and (max-width: 700px) {}
@media screen and (max-width: 600px) {
	#section00-wrap .flex {
		max-width: 500px;
	}
	#section00-wrap .flex .image-area {
	    margin-top: -20%;
	    margin-left: 30%;
	}
	#section00-wrap .flex .bg-area {
		padding-top: 80%;
	}
	#section00-wrap .flex .bg-area h1 {
		font-size: 1.6em;
	}
	#section00-wrap .flex .bg-area h2 {
		font-size: 2em;
	}
	#section01-wrap .cont-area .contents .inst {
		font-size: 1.5em;
	}
	#section01-wrap .point-area .point-list li {
		font-size: 1em;
	}
	
	#section05-wrap {
		padding: 40px 20px;
	}
	#section05-wrap .category {
		width: 100%;
		padding: 20px;
	}
	#search-wrap {
		flex-flow: wrap;
    	-webkit-flex-flow: wrap;
    	-ms-flex-wrap: wrap;
    	padding: 10px;
	}
	#search-wrap .submit {
		width: 100%;
		margin: 5px 0;
	}
	#search-wrap select {
		width: 29%; 
	}
	#search-wrap input {
		width: 69%;
	}
	#modal-area {
		width: 100%;
		height: 100%;
	}
	#modal-wrap {
		padding-top: 62px;
	}
	#templete-wrap .templete-flex {
		margin: 0;
	}
}
@media screen and (max-width: 480px) {
	#section03-wrap .info-area,
	#form-mail-area {
		padding: 40px 20px;
	}
	
	#footer-area .wrap {
		padding: 60px 20px;
	}
}