#section00 {
	position: relative;
	width: 100%;
	height: 500px;
	overflow: hidden;
}
#sect00-canvas {
	
}
#section00 .image-area {
	flex-direction: column;
	justify-content: center;
}
.sect00--img1 {
	background-size: auto 80%;
	background-position: 16.5% 50%;
}
#section00 .main-title-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
}
#section00 .main-title .title {
	position: relative;
	overflow: hidden;
}
#section00 .main-title .title span,
#section00 .main-subject span {
	display: block;
	max-width: 800px;
	opacity: 0;
	visibility: hidden;
}
#section00 .main-title .title.no1 span {
	transform: translate3d(30%, 0, 0);
}
#section00 .main-title .title.no2 span,
#section00 .main-title .title.no0 span {
	transform: translate3d(-30%, 0, 0);
}
#section00 .main-subject span {
	transform: translate3d(10%, 0, 0);	
}
#section00 .image-slide.selected .main-title .title span,
#section00 .image-slide.selected .main-subject span {
	animation-name: mainslide;
	animation-duration: 1s;
	animation-fill-mode: forwards;
}
@keyframes mainslide {
	to {
		opacity: 1;
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}
}

#section00-wrap {
	position: absolute;
	bottom: 60px;
	right: 80px;
	transform: translateX(-50%);
}

#section00-wrap .btn-area {
	padding: 20px 0 5vh;
	text-align: center;
}
#section00-wrap .btn {
	display: inline-block;
	font-size: 1.4em;
	font-weight: 600;
	color: #fff;
	width: 100%;
	max-width: 320px;
	padding: 20px 0;
	cursor: pointer;
	background-color: #388f9e;
	transition: background-color .3s;
}
#section00-wrap .btn:hover {
	background-color: #202b5e
}
#scroll-area {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}
#scroll-area .icn-area {
 	position: relative;
 	margin: 0 auto;
 	text-align: right;
}




@media screen and ( max-width: 800px ) {
	#section00 .main-title-wrap::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,.5);
	}
	
}