@font-face { font-family: 'NEXEN_TIRE'; src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_eight@1.0/NEXEN_TIRE_Regular.woff') format('woff'); font-weight: 400; font-style: normal; }
@font-face { font-family: 'NEXEN_TIRE'; src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_eight@1.0/NEXEN_TIRE_Bold.woff') format('woff'); font-weight: 900; font-style: normal; }
@font-face { font-family: 'KCC-eunyoung'; src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/KCC-eunyoung-Regular.woff') format('woff'); font-weight: normal; font-style: normal; }
/*@font-face { font-family: 'Glyphter'; src: url('/Glyphter.woff') format('woff'); font-weight: normal; font-style: normal; }*/

.ft-fd { font-family: NanumSquare; }
.ft-f1 { font-family: 'NEXEN_TIRE'; }
.ft-f2 { font-family: 'KCC-eunyoung'; }
html:lang(ja) .ft-f0-ja { font-family: sans-serif; }
html:lang(ja) .ft-b-ja { font-weight: 900; }

.ft-mi { 
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga'; 
}
.ft-mio { 
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga'; 
}
.ft-mit { 
  font-family: 'Material Icons Two Tone';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga'; 
}


.bg-cover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bg-cover.bg-c1::before { background-color: rgba(101,101,101, 0.2); }
.bg-cover.bg-c2::before { background-color: rgba(0,0,0, 0.4); }
.bg-cover.bg-wh::before { background-color: rgba(255,255,255, 0.7); }
.bg-cover.bg-wh1::before { background-color: rgba(255,255,255, 0.4); }
.bg-filter-bk { background-color: rgba(0, 0, 0, 0.4); }

.bg-clr-0-op9 { background-color: rgba(24, 1, 93, .9); }


* {
	box-sizing: border-box;
	/*word-break: break-all;*/
	/*word-break: keep-all;*/
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
ul {
	list-style: none;
}
a {
	color: inherit;
	text-decoration: none;
}
html {
	font-family: NanumSquare, sans-serif;
	font-weight: 400;
	line-height: 1.4;
	height: 100%;
}
html:lang(ja) { 
	/*font-family: Nanum Gothic;*/
	font-family: sans-serif;
}
body {
	font-size: 14px;
	width: 100%;
	height: 100%;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	/*overflow-x: hidden;*/
}
body.hidden {
	overflow-y: hidden;
}
input,textarea,select {
	padding: 3.5px 5px;
	border: none;
	outline: none;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #ccc;
}
input::-moz-placeholder,
textarea::-moz-placeholder { /* Firefox 19+ */
	color: #ccc;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder { /* Edge */
	color: #ccc;
}
input:-moz-placeholder,
textarea:-moz-placeholder { /* Firefox 18- */
	color: #ccc;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder { /* IE 10+ */
	color: #ccc;
}
.jhc-loading-area {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 40px;
	text-align: center;
}
.jhc-loading-svg {
	display: inline-block;
	width: 100px;
	height: 100px;
	background-image: url('/dist/img/loading/hexa.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/*animation: loading-svg-spin 1s infinite linear;*/
}
@media all and (-ms-high-contrast:none) {
 .jhc-loading-svg { background-image: url('/dist/img/loading/loading.svg'); animation: loading-svg-spin 1s infinite linear; } /* IE10 */
 *::-ms-backdrop, .jhc-loading-svg { background-image: url('/dist/img/loading/loading.svg'); animation: loading-svg-spin 1s infinite linear; } /* IE11 */
}
@keyframes loading-svg-spin {
	100% {
		transform: rotate(360deg);
	}
}


#loadingSVGarea {
	text-align: center;
	/* margin: 20px 0; */
}
.loadCircle {
	fill: none;
	stroke: #808080;
}
#page {
	color: #000;
	text-align: center;
	margin: 30px 0;
}
#leftsub, #rightsub {
	display: inline-block;
	margin:0;
	padding:0;
	vertical-align: middle;
}
#leftsub div, #rightsub div {
	display: inline-block;
	margin: 0 1px;
	vertical-align: middle;
}
#leftsub .page-arrow, #rightsub .page-arrow {
	width: 14px;
	height: 18px;
	vertical-align: middle;
}
.arrowpoly {
	fill: none;
	stroke: #9CA4A6;
	stroke-width: 1.5;
	stroke-linecap: round;
	stroke-linejoin: round;
}
#page .now {
	font-weight: 900;
}
#prev, #next {
	margin: 0;
	padding: 0;
}
#page .end {
	font-size: 15px;
}
#page-area {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	overflow: hidden;
}
#page-part {
	display: inline-block;
	position: relative;
	text-align: left;
	white-space: nowrap;
}
.page-block {
	display: inline-block;
	position: relative;
}
.page-block span {
	padding: 0 5px;
}
.page-block span.selected {
	font-weight: bold;
	color: blue;
	font-size: 15px;
}
#prev, #next, #leftsub div, 
#rightsub div, .page-block span {
	cursor: pointer;
}
#page-404-container {
	position: relative;
	width: 100%;
	height: 100vh;
}
#page-404-container .page {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
#page-404-container .page .status {
	font-size: 40vw;
	font-weight: 600;
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: .1;
	transform: translate(-50%, -50%);
}
#page-404-container .page .title {
	font-size: 4vw;
	font-weight: 900;
	position: relative;
	max-width: 600px;
	text-align: center;
}
#page-404-container .page .btn {
	display: block;
	color: #fff;
	position: relative;
	margin-top: 20px;
	padding: 20px 40px;
	background-color: #d62323;
}

#head-area {
	z-index: 101;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	/*box-shadow: 0 0 40px 10px rgba(0,0,0,.1);*/
	box-shadow: 0px 4px 0px 0px rgba(0,0,0,.1);
	background-color: #fff;
}
#head-area .head-part {
	transition: opacity 1.8s;
}
#head-area.on .head-part {
	opacity: 1;
}
#head-area .top-area {
	display: flex;
  justify-content: space-between;
	position: relative;
	width: 100%;
}
#head-area .logo-wrap {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}
#index-list li {
	width: 8px;
	height: 8px;
	margin: 0 2%;
	cursor: pointer;
	border-radius: 20px;
	background-color: #fff;
	opacity: .5;
}
#index-list li.selected {
	opacity: 1;
}

#logo-area-top {
	width: 160px;
}
.logo {
	width: 100%;
	padding-top: 28%;
	background-image: url('/dist/img/logo/logo.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}
.logo.wh {
	background-image: url('/dist/img/logo/logo-wh.svg');
}
.logo.bk {
	background-image: url('/dist/img/logo/logo-bk.svg');
}

#menu-cont-area {
	z-index: 5;
	position: relative;
}
#menu-cont {
	display: inline-block;
	position: relative;
	padding: 0 20px;
}
/*#menu-cont::after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
}*/
#menu-cont .menu-item {
	display: inline-block;
	position: relative;
	cursor: pointer;
	background: #fff; 
}
#menu-cont .menu-item .item {
	z-index: 1;
	display: inline-block;
	position: relative;
	padding: 26px 15px;
	background: #fff;
	transition: color .2s;
}
/* #menu-cont .menu-item:hover .item,
#menu-cont .menu-item.selected .item {
	color: #0CE8CE;
} */
#menu-cont .sub-menu-list {
	position: absolute;
	bottom: 0px;
	left: 0;
	min-width: 200px;
	visibility: hidden;
	border-top: 4px solid #333;
	/*pointer-events: none;*/
	opacity: 0;
	transform: translate(-10px, 100%);
	transition: .3s .2s;
}
#menu-cont .menu-item:hover .sub-menu-list {
	visibility: visible;
	/*pointer-events: auto;*/
	opacity: 1;
	transform: translate(0%, 100%);
}
#menu-cont .menu-item:hover .sub-menu-list {
	z-index: 1;
}
#menu-cont .sub-menu-list .sub-menu-item {
	border-bottom: 3px solid #eee;
	opacity: 0;
	transition: .3s;
}
#menu-cont .sub-menu-list .sub-menu-item:hover,
#menu-cont .sub-menu-list .sub-menu-item.selected {
	color: #fff;
	background-color: #232250;
}
#menu-cont .menu-item:hover .sub-menu-item,
#menu-cont .menu-item.selected .sub-menu-item {
	opacity: 1;
	transition: opacity .3s .35s;
}
#menu-area {
	display: none;
	z-index: 6;
	position: relative;
	cursor: pointer;
	-webkit-backface-visibility: hidden;
}
#menu-area:hover #menu-wrap {
	transform: translate3d(-7px ,7px ,0);
	-webkit-transform: translate3d(-7px ,7px ,0);
}
#menu-area.on #menu-wrap {
	transform: translate3d(5px ,-5px ,0);
	transition-timing-function: cubic-bezier(1,-3.59,0,4.52);
	transition: .1s;
	-webkit-transform: translate3d(5px ,-5px ,0);
	-webkit-transition-timing-function: cubic-bezier(1,-3.59,0,4.52);
	-webkit-transition: .1s;
}
#menu-area.on {
	transition: .3s .6s;
}
#menu-icon {
	z-index: 6;
	position: relative;
	padding: 20px;
}
#menu-icon li {
	width: 30px;
	height: 2px;
	margin: 4px;
	background-color: #202b5e;
	transition: .6s;
	-webkit-transition: .6s;
}
#menu-icon li.line1 {
	animation: aniLine1dft .6s forwards;
}
#menu-icon li.line3 {
	animation: aniLine3dft .6s forwards;
}
@keyframes aniLine1dft {
	0% {
		transform: translate3d(0,6px,0) rotateZ(45deg);
	}
	50% {
		transform: translate3d(0,6px,0) rotateZ(0deg);
	}
	100% {
		transform: translate3d(0,0,0) rotateZ(0deg);
	}
}
@keyframes aniLine3dft {
	0% {
		transform: translate3d(0,-6px,0) rotateZ(-45deg);
	}
	50% {
		transform: translate3d(0,-6px,0) rotateZ(0deg);
	}
	100% {
		transform: translate3d(0,0,0) rotateZ(0deg);
	}
}


#menu-area.on li.line1 {
	/* transform: translate(0.8px,-2.7px) rotate(45deg) scale(1.3, 1);
	-webkit-transform: translate(0.8px,-2.7px) rotate(45deg) scale(1.3, 1); */
	animation: aniLine1 .6s forwards;
}
#menu-area.on li.line2 {
	opacity: 0;
}
#menu-area.on li.line3 {
	/* transform: translate(-1.7px,-22.3px) rotate(-45deg) scale(1.3, 1) ;
	-webkit-transform: translate(-1.7px,-22.3px) rotate(-45deg) scale(1.3, 1); */
	animation: aniLine3 .6s forwards;
}
@keyframes aniLine1 {
	50% {
		transform: translate3d(0,6px,0) rotateZ(0deg);
	}
	100% {
		transform: translate3d(0,6px,0) rotateZ(45deg);
	}
}
@keyframes aniLine3 {
	50% {
		transform: translate3d(0,-6px,0) rotateZ(0deg);
	}
	100% {
		transform: translate3d(0,-6px,0) rotateZ(-45deg);
	}
}
.lang-menu:hover .sub-lang-list {
	opacity: 1;
	visibility: visible;
	transform: translate(0%, 100%);
}
.sub-lang-list {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transform: translate(0%, 110%);
	transition: .3s;
}
#container {
	z-index: 1;
	position: relative;
	width: 100%;
	height: 100%;
}
#wrapper {
	position: relative;
	width: 100%;
	overflow: hidden;
	/*min-height: 100vh;*/
}

.checkbox-type1 input[type="checkbox"],
.checkbox-type1 input[type="radio"] { display: none; }
.checkbox-type1 .rect {
	display: inline-block;
	position: relative;
	width: 20px;
	height: 20px;
	cursor: pointer;
}
.checkbox-type1 .rect::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 7px;
	height: 13px;
	border-color: #ff0000;
	border-width: 2px;
	border-right-style: solid;
	border-bottom-style: solid;
	/*opacity: 0;
	visibility: hidden;*/
	transform: scale(0,0) rotate(-45deg) translate(2px, -4px);
	transition: .1s;
}
.checkbox-type1 input:checked + .rect::before,
.checkbox-type1 input:checked + label .rect::before {
	/*opacity: 1;
	visibility: visible;*/
	transform: scale(1,1) rotate(45deg) translate(2px, -5px);
}






.gallery-imgArea {
}
.gallery-imgArea .wrap {
	position: relative;
	width: 100%;
	overflow: hidden;
}
.gallery-imgslider {
	display: inline-block;
	position: relative;
	top: 0;
	width: 100%;
	white-space: nowrap;
	text-align: center;
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
}
.gallery-imgslider li {
	display: inline-block;
	position: relative;
	top: 0%;
	width: 100%;
	box-sizing: border-box;
	vertical-align: middle;
}
.gallery-imgslider li .image {
	position: relative;
	width: 100%;
	padding-top: 66%;
	background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.gallery-imgArea .bullet {
	z-index: 100;
	width: 100%;
	position: relative;
	/* margin-top: -44px; */
	padding: 15px;
	text-align: center;
}
.gallery-imgArea .bullet li {
	display: inline-block;
	width: 20px;
	height: 5px;
	background-color: #fff;
	margin: 0 2px;
	cursor: pointer;
}
.gallery-imgArea .bullet li.selected {
	background-color: #202b5e;
}





#search-wrap {
	display: -webkit-box;
	-webkit-box-pack: center;
	-webkit-box-align: center;
	
	display: flex;
	display: -webkit-flex;
	display: -ms-flexbox;
	
	-webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    
	width: 100%;
	max-width: 800px;
	margin: auto;
	padding: 0 5px 20px;
}
#search-wrap select {
	width: 24%;
}
#search-wrap input {
	width: 50%;
}
#search-wrap select,
#search-wrap input {
	padding: 5px 10px;
	border-radius: 0px;
	outline: none;
	border: 1px solid #DDDDDD;
	background-color: #fff;
	appearance: none;
	-webkit-appearance: none;
	transition: .6s;
}
#search-wrap select:focus,
#search-wrap input:focus {
	box-shadow: 0 0 5px #f27903;
	border: 1px solid #f27903;
}
#search-wrap .submit {
	color: #fff;
	width: 24%;
	padding: 6px;
	text-align: center;
	cursor: pointer;
	border-radius: 0px;
}

#transition-mask {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	
	visibility: hidden;
	pointer-events: none;
	opacity: 0;
}
#transition-mask .transition-logo {
	position: relative;
	width: 100%;
	max-width: 250px;
	margin: auto;
	padding-top: 82px;
	background-image: url('/dist/img/logo-wh.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat; 
	opacity: 0;
	transform: scale3d(.8, .8, 1);
}
#transition-mask.on .transition-logo {
	animation-name: fadeLogo;
	animation-duration: 1.3s;
	animation-delay: .8s;
}
@keyframes fadeLogo {
	60% {
		transform: scale3d(0, 0, 1);
		opacity: 0;
	}
	60% {
		transform: scale3d(1, 1, 1);
		opacity: 1;
	}
	70% {
		transform: scale3d(1, 1, 1);
		opacity: 1;
	}
	100% {
		transform: scale3d(1, 1, 1);
		opacity: 0;
	}
}
#transition-mask .transition-sheet {
	position: absolute;
	width: 150%;
	height: 100%;
	/*transform-origin: 50% 0;
	transform: translate3d(0, 100%, 0);*/
}
#transition-mask .transition-sheet.no1 {
	transform-origin: 150% 50%;
	transform: translate3d(150%, 0, 0);
}
/*#transition-mask .transition-sheet.no1 {
	transform-origin: 150% 50%;
	transform: translate3d(150%, 0, 0) skewX(-40deg);
}
#transition-mask .transition-sheet.no2 {
	transform-origin: 0 50%;
	transform: translate3d(-150%, 0, 0) skewX(40deg);
}*/
#transition-mask.on .transition-sheet {
	animation-duration: 2.6s;
	/*animation-timing-function: cubic-bezier(.4,.6,.2,1);*/
} 
#transition-mask.on .transition-sheet.no1 {
	animation-name: mask-ani;
	/*animation-timing-function: cubic-bezier(.4,.6,.2,1);*/
}
#transition-mask.on .transition-sheet.no2 {
 	animation-name: mask-ani2;
	animation-duration: 3s;
	animation-delay: .5s;
} 
#transition-mask.on {
	z-index: 999;
	visibility: visible;
	pointer-events: auto;
	opacity: 1;
}
@keyframes mask-ani {
	40% {
		transform: translate3d(0, 0, 0);
	}
	70% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-120%, 0, 0);
	}
}
@keyframes mask-ani1 {
	/*0% {
		transform: translate3d(120%, 0, 0) skewX(-40deg);
	}*/
	50% {
		transform: translate3d(0, 0, 0) skewX(0deg);
	}
	70% {
		transform: translate3d(0, 0, 0) skewX(0deg);
	}
	100% {
		transform: translate3d(-120%, 0, 0) skewX(20deg);
	}
}
@keyframes mask-ani2 {
	/*0% {
		transform: translate3d(0, 0%, 0);
	}*/
	50% {
		transform: translate3d(0, 0, 0) skewX(0deg);
	}
	70% {
		transform: translate3d(120%, 0, 0) skewX(-20deg);
	}
	100% {
		transform: translate3d(120%, 0, 0) skewX(-20deg);
	}
}

#modal-area {
	z-index: 101;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 90%;
	height: 98%;
	margin: auto;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transform: translate3d(-55%, -50%, 0);
	/*transform: translate3d(-50%, -50%, 0) scale3d(.4, .4, 1);*/
	transition: transform .6s .2s	, opacity .6s .2s, visibility .6s .2s;
}
#modal-area.on {
	opacity: 1;
	visibility: visible;
	pointer-events: auto;
	transform: translate3d(-50%, -50%, 0);
	/*transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);*/
}
#modal-area .close-area {
	display: block;
	z-index: 100;
	position: absolute;
	top: 70px;
	left: 0;
	width: 50px;
	height: 50px;
	padding: 10px;
}
#modal-area .close-area .close {
	width: 100%;
	height: 100%;
	cursor: pointer;
}

#modal-area .close-area .close svg {
	stroke-width: 3;
}
#modal-wrap {
	z-index: 3;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding-top: 70px;
	/* background: rgba(0,0,0,.8); */
}
#templete {
	display: flex;
	flex-direction: column;
   	
  position: relative;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	/* background: linear-gradient(41deg,#00b0f0 0,#deedf3 100%); */
}
#templete-wrap {
	position: relative;
	width: 100%;
	margin: auto;
	/* background: linear-gradient(-41deg,#00b0f0 0,#deedf3 100%); */
}
#templete-wrap .templete-flex {
	position: relative;
	min-height: 100%;
	margin: 50px 0;
	padding: 0;
	/* box-shadow: 4px 4px 16px rgba(0,0,0,.3); */
	background-color: #fff;
}
#templete-wrap .cont-area h2 {
  font-size: 1.4em;
  font-weight: 600;
  color: #969696;
  padding-bottom: 20px;
}
#templete-wrap .cont-area {
	font-size: 1.2em;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	padding: 80px 20px;
}
#templete-wrap .agree-list {
	list-style: disc;
	line-height: 1.6em;
	padding: 0 20px;
}

#g-map-area {
	z-index: 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 40px;
	background: #fff;
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
	transition-property: opacity, visibility, z-index;
	transition-duration: 1s;
}
#g-map-area.show {
	z-index: 120;
	pointer-events: auto;
	visibility: visible;
	opacity: 1;
	transition-property: opacity, visibility;
}
#g-map-area .close-area {
	display: block;
	z-index: 111;
	position: absolute;
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
	padding: 5px;
	box-sizing: border-box;
}
#g-map-area .close-area .close {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	cursor: pointer;
}
#g-map-area .close-area .close svg {
	stroke-width: 3;
}
#g-map {
	display: inline-block;
	width: 100%;
	height: 100%;
}

#pop-notice {
	z-index: 1;
	display: none;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	color: #fff;
	padding: 15px;
	/*background-color: #0f29ff;*/
	transform: translateY(100%);
	animation: popup .6s 1s forwards;
}
#pop-notice.off {
	animation: popupOff .6s forwards;
}
@keyframes popupOff {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(100%);
	}
}
@keyframes popup {
	to {
		transform: translateY(0);
	}
}
#pop-notice .notice-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
  max-width: 800px;
}
#pop-notice .notice {
	padding: 10px;
	border-bottom: 1px solid #fff;
}
#pop-notice .title {
	font-size: 1.4em;
	font-weight: 800;
	padding-bottom: 8px;
}
#pop-notice .subject {
	font-size: 1.1em;
	font-weight: 500;
	color: #999;
	padding-bottom: 4px;
}
#pop-notice .contents {
	line-height: 1.4em;
}
#pop-notice .contents b {
	font-weight: 600;
	color: #cc292a;
}
#pop-notice .btn-area {
	padding: 10px 20px;
}
#pop-notice .btn {
	display: block;
	padding: 15px 20px;
	cursor: pointer;
	border-radius: 3px;
	/*background-color: #00c586;*/
}

.sect-line::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 2px;
}
.footer-center-line::before,
.footer-center-line::after {
	content: '';
	position: relative;
	flex: 1 0 auto;
	height: 2px;
}
.vline::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 4px;
	height: 100%;
}

.video-center {
  min-width: 100%;
  width: auto;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}